import React from 'react';
import styled from 'styled-components';

const Typography = styled('p')``;
const Section = styled('section')`
  padding: 16em 6em 4em;
`;

const NotFoundPage = () => (
  <Section>
    <Typography variant="display1">NOT FOUND</Typography>
    <Typography>
      You just hit a route that doesn&#39;t exist... the sadness.
    </Typography>
  </Section>
);

export default NotFoundPage;
